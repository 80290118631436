import React, { useState, useEffect } from "react";
import { Container, Grid } from "@mui/material";
// Sections
import ResponsiveDrawer from "../../components/Drawer";
// Assets
import "./../../style/aboutApp.css";
import { getAllDocuments } from "../../utils/firebaseQueries";

export default function Dashboard() {
  const [users, setUsers] = useState([]);
  const getUsers = async () => {
    try {
      const res = await getAllDocuments("Users");
      setUsers([...res]);
    } catch (error) {
      // setMessage(error.message);
      // setMessageType("error");
      // setOpen1(true);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <ResponsiveDrawer title="Dashborad">
      <Container style={{ marginTop: 50, marginBottom: 50 }}>
        <h1>Welcome to Dhanak Dashboard</h1>
        <Grid container spacing={2} alignItems="center">
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <div className="dashboardCard">
              <h2>{users.length}</h2>
              <div>Users</div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </ResponsiveDrawer>
  );
}
