import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Landing from "../screens/Landing";
import PrivacyPolicy from "../screens/PrivacyPolicy";
import Dashboard from "../screens/Admin/Dashboard";
import Users from "../screens/Admin/Users";
import Login from "../screens/Admin/Login";
import HowToDeleteUserData from "../screens/DeleteData";
import Community from '../screens/Community'
import AboutUs from "../screens/AboutUs";
import FAQ from "../screens/Faq";

const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route
          path="/how-to-delete-user-data"
          element={<HowToDeleteUserData />}
        />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/community" element={<Community />} />
        <Route path="/admin/dashboard" element={<Dashboard />} />
        <Route path="/admin/users" element={<Users />} />
        <Route path="/admin/login" element={<Login />} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
