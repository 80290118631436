import React from "react";
// Assets
import HeaderImage from "../../assets/headerImage.svg";
import ArrowIcon from "../../assets/arrowIcon.svg";
import { Container, Grid } from "@mui/material";
import TopNavbar from "../Nav2/TopNavbar";
import { useNavigate } from "react-router-dom";
import "./../../style/header.css";

export default function Header({ header }) {
  const navigate = useNavigate();
  return (
    <div className="landingPageHeader">
      <TopNavbar />
      {header && (
        <Container style={{ marginTop: 40 }}>
          <Grid container>
            <Grid item xl={6} lg={6} md={6} xs={12}>
              <div>
                <div className="heading">
                  Yesterday’s misfortune becomes a life turning point to create
                  a hopeful tomorrow
                </div>
                <p className="headerDes">
                  Dhanak stands for marginalized communities of Pakistan, be it
                  transgenders, minorities or underprivileged entrepreneurs or
                  skilled personals .
                </p>
                <div className="headerButtons">
                  <a
                    href="https://expo.dev/accounts/beerbal/projects/Dhanak/builds/eda5614d-685f-4909-8d74-49b89c513ac0"
                    target="_blank"
                  >
                    <button className="getStartedBtn">Download Now</button>
                  </a>
                  <button
                    className="exploreMoreBtn"
                    onClick={() => navigate("/about-us")}
                  >
                    Explore More
                    <img src={ArrowIcon} />
                  </button>
                </div>
              </div>
            </Grid>
            <Grid item xl={6} lg={6} md={6} xs={12}>
              <div style={{ textAlign: "center" }}>
                <img src={HeaderImage} className="headerImage" />
              </div>
            </Grid>
          </Grid>
        </Container>
      )}
    </div>
  );
}
