import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
// Components
import Sidebar from "../Nav2/Sidebar";
import Backdrop from "../Elements/Backdrop";
// Assets
import LogoIcon from "../../assets/logo.svg";
import { HiMenuAlt3, HiMenu } from "react-icons/hi";
// import MenuIcon from "./../../assets/svg/menu.svg";
// CSS
import "./../../style/nav.css";

export default function TopNavbar() {
  const navigate = useNavigate();
  const [y, setY] = useState(window.scrollY);
  const [sidebarOpen, toggleSidebar] = useState(false);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorEl1);

  useEffect(() => {
    window.addEventListener("scroll", () => setY(window.scrollY));
    return () => {
      window.removeEventListener("scroll", () => setY(window.scrollY));
    };
  }, [y]);

  useEffect(() => {
    var isUserLogin = localStorage.getItem("userData");
    if (isUserLogin) {
      setIsUserLoggedIn(true);
    }
    // console.log("====isUserLogin=====", isUserLogin);
  }, []);

  return (
    <>
      <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
      {sidebarOpen && <Backdrop toggleSidebar={toggleSidebar} />}
      <Wrapper
        className="flexCenter animate"
        style={y > 100 ? { height: "60px" } : { height: "80px" }}
      >
        <NavInner className="container flexSpaceCenter">
          <Link className="pointer flexNullCenter" to="/" smooth={true}>
            <img src={LogoIcon} width={70} height={70} />
            <h1
              style={{ marginLeft: "15px", color: "#000000" }}
              className="font20 extraBold"
            >
              Dhanak
            </h1>
          </Link>
          <BurderWrapper
            className="pointer"
            onClick={() => toggleSidebar(!sidebarOpen)}
          >
            <HiMenuAlt3 color="#000" size={30} />
            {/* <img src={MenuIcon} /> */}
          </BurderWrapper>
          <UlWrapper className="flexNullCenter">
            <li className="medium font15 pointer">
              <Link style={{ padding: "10px 15px", color: "#000000" }} to="/">
                Home
              </Link>
            </li>
            <li className="medium font15 pointer">
              <Link
                style={{ padding: "10px 15px", color: "#000000" }}
                to="/about-us"
              >
                About Us
              </Link>
            </li>
            <li className="medium font15 pointer">
              <Link
                style={{ padding: "10px 15px", color: "#000000" }}
                to="/privacy-policy"
              >
                Privacy Policy
              </Link>
            </li>
            <li className="medium font15 pointer">
              <Link
                style={{ padding: "10px 15px", color: "#000000" }}
                to="/community"
              >
                Community
              </Link>
            </li>
            <li className="medium font15 pointer">
              <a
                href="#ourGoals"
                style={{ padding: "10px 15px", color: "#000000" }}
              >
                Our Goals
              </a>
            </li>
            <li className="medium font15 pointer">
              <a
                href="#ourteam"
                style={{ padding: "10px 15px", color: "#000000" }}
              >
                Our Team
              </a>
            </li>
            <li className="medium font15 pointer">
              <a
                href="#contact"
                style={{
                  padding: "10px 15px",
                  color: "#000000",
                }}
              >
                Contact Us
              </a>
            </li>
          </UlWrapper>
        </NavInner>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.nav`
  width: 100%;
  top: 0;
  left: 0;
  // position: fixed;
`;
const NavInner = styled.div`
  position: relative;
  height: 100%;
`;
const BurderWrapper = styled.button`
  outline: none;
  border: 0px;
  background-color: transparent;
  height: 100%;
  padding: 0 15px;
  display: none;
  @media (max-width: 760px) {
    display: block;
  }
`;
const UlWrapper = styled.ul`
  display: flex;
  @media (max-width: 760px) {
    display: none;
  }
`;
const UlWrapperRight = styled.ul`
  @media (max-width: 760px) {
    display: none;
  }
`;
