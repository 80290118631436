import React from "react";
import "./../style/Landing.css";
import { Container, Grid } from "@mui/material";
// Sections
import Header from "../components/Sections/Header";
import Footer from "../components/Sections/Footer";
// Assets
import "./../style/termsConditions.css";

export default function PrivacyPolicy() {
  return (
    <>
      <Header header={false} />

      <div style={{ marginBottom: 50 }}>
        <div className="termsConditionHeader">
          <h1>Privacy Policy</h1>
        </div>
        <Container>
          <p className="lastUpdatedTxt">Effective Date: 01 November 2023</p>
          <p className="termsWelcomeDes">
            Thank you for choosing <b>DhanakApp</b>, a community posting app
            designed to showcase skills, facilitate service selling, and connect
            individuals, with a special focus on providing opportunities for
            transgender people. This Privacy Policy is meant to help you
            understand what data we collect, why we collect it, and how we use
            and protect it.
          </p>
          <p className="termsWelcomeDes">
            By using the Dhanakapp app, you agree to the terms outlined in this
            Privacy Policy.
          </p>
          <p className="termsConditionsHeading" style={{ marginTop: 20 }}>
            1. Information We Collect
          </p>
          <p className="termsWelcomeDes2">
            <b className="point">1.1 Account Information:</b>
          </p>
          <p className="privacyPolicyList">
            To use DhanakApp, users are required to create an account with a
            username, email address, and password. Additional optional
            information, such as a profile picture and a brief bio, can be
            provided to enhance user profiles.
          </p>
          <p className="termsWelcomeDes2">
            <b className="point">1.2 Skill and Service Information:</b>
          </p>
          <p className="privacyPolicyList">
            Users have the option to showcase their skills, services, and
            talents on the platform. This may include but is not limited to
            images, descriptions, and other content related to the services they
            offer.
          </p>
          <p className="termsWelcomeDes2">
            <b className="point">1.3 Communication Data:</b>
          </p>
          <p className="privacyPolicyList">
            We collect information about your interactions with other users on
            the platform, such as messages, likes, comments, and other social
            interactions.
          </p>
          <p className="termsConditionsHeading" style={{ marginTop: 20 }}>
            2. How We Use Your Information
          </p>
          <p className="termsWelcomeDes2">
            <b className="point">2.1 Providing and Improving Services:</b>
          </p>
          <p className="privacyPolicyList">
            We use the collected information to provide, maintain, and improve
            the features and services of the Dhanakapp app, ensuring a better
            user experience.
          </p>
          <p className="termsWelcomeDes2">
            <b className="point">2.2 Personalization:</b>
          </p>
          <p className="privacyPolicyList">
            To enhance user experience, we may use your information to
            personalize the content and services you see, based on your
            preferences and interactions.
          </p>
          <p className="termsWelcomeDes2">
            <b className="point">2.3 Communication:</b>
          </p>
          <p className="privacyPolicyList">
            We may use your email address to send you important updates,
            notifications, or promotional content related to the Dhanakapp app.
            Users can opt-out of promotional emails at any time.
          </p>
          <p className="termsWelcomeDes2">
            <b className="point">2.4 Legal Compliance:</b>
          </p>
          <p className="privacyPolicyList">
            We may process your information to comply with legal obligations,
            resolve disputes, and enforce our terms and policies.
          </p>
          <p className="termsConditionsHeading" style={{ marginTop: 20 }}>
            3. Data Security
          </p>
          <p className="privacyPolicyList">
            We prioritize the security of your data and have implemented
            measures to protect it from unauthorized access, disclosure,
            alteration, and destruction. All data transmission is encrypted
            using industry-standard protocols to ensure secure communication.
            However, no method of transmission over the internet or electronic
            storage is completely secure, so we cannot guarantee absolute
            security.
          </p>
          <p className="termsConditionsHeading" style={{ marginTop: 20 }}>
            4. User Control and Data Deletion
          </p>
          <p className="privacyPolicyList">
            Users have the right to access, correct, or delete their personal
            information. You can do this by accessing your account settings
            within the app. We will retain your data for 6-12 months after
            account deletion for operational purposes, after which it will be
            securely deleted. To request the deletion of your profile, you can
            also email us at{" "}
            <b>
              <i>humeradhanak@gmail.com</i>
            </b>
          </p>
          <p className="termsConditionsHeading" style={{ marginTop: 20 }}>
            5. No Third-Party Services for Analytics
          </p>
          <p className="privacyPolicyList">
            Dhanakapp app does not use any third-party services for analytics,
            advertising, or any other purposes related to user data.
          </p>
          <p className="termsConditionsHeading" style={{ marginTop: 20 }}>
            6. Changes to the Privacy Policy
          </p>
          <p className="privacyPolicyList">
            We may update this Privacy Policy as needed to reflect changes in
            the app's features, user feedback, or legal requirements. Users will
            be notified of any significant changes, and the updated policy will
            be effective upon posting.
          </p>
          <p className="termsConditionsHeading" style={{ marginTop: 20 }}>
            7. Contact Us
          </p>
          <p className="privacyPolicyList">
            If you have any questions, concerns, or requests regarding your
            privacy, please contact us at{" "}
            <a href="mailto:humeradhanak@gmail.com">humeradhanak@gmail.com</a>.
          </p>
          <p className="termsConditionsHeading" style={{ marginTop: 20 }}>
            8. Delete User Data on Request and End-to-End Encryption
          </p>
          <p className="termsWelcomeDes2">
            <b className="point">8.1 Deletion of User Data:</b>
          </p>
          <p className="privacyPolicyList">
            We respect your right to control your personal information. If you
            decide to discontinue using the DhanakApp app or wish to delete your
            account, you can request the deletion of your user data. To initiate
            this process, please access your account settings within the app and
            follow the provided instructions. Upon receipt of your request, we
            will promptly delete your account and associated data. Please note
            that we will retain your data for 6-12 months after account deletion
            for operational purposes, after which it will be securely deleted.
            You can also email us at{" "}
            <a href="mailto:humeradhanak@gmail.com">humeradhanak@gmail.com</a>{" "}
            to request deletion.
          </p>
          <p className="termsWelcomeDes2">
            <b className="point">8.2 End-to-End Encryption:</b>
          </p>
          <p className="privacyPolicyList">
            Your privacy and the security of your data are of utmost importance
            to us. To safeguard your information during transmission, we
            implement end-to-end encryption using industry-standard protocols.
            This means that your data is securely encoded from your device and
            can only be decrypted by the intended recipient. Be assured that
            your messages, transactions, and any sensitive information exchanged
            within the Dhanakapp app are protected with the highest level of
            encryption.
          </p>
          <p className="termsWelcomeDes2">
            <b className="point">8.3 Security of Encrypted Data</b>
          </p>
          <p className="privacyPolicyList">
            While end-to-end encryption provides a robust layer of security,
            it's essential to note that no method of data transmission or
            storage can be entirely invulnerable. We continuously monitor and
            update our security measures to stay ahead of potential risks and
            vulnerabilities.
          </p>
          {/* <p className="privacyPolicyList">
            This addition emphasizes the commitment to user data privacy by
            offering a clear process for data deletion on request and ensuring
            that data transmission is protected through end-to-end encryption.
          </p> */}
        </Container>
      </div>
      <Footer />
    </>
  );
}
