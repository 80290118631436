import React from "react";
import "./../style/Landing.css";
import { Container, Grid } from "@mui/material";
// Sections
import Header from "../components/Sections/Header";
import Footer from "../components/Sections/Footer";
import AboutApp from "../components/Sections/AboutApp";
import Contact from "../components/Sections/Contact";
// Assets
import SamrtPhoneIcon from "./../assets/smartphone.svg";
import PlanningIcon from "./../assets/planning.svg";
import PadlockIcon from "./../assets/padlock.svg";
import ProfileImage1 from "./../assets/profileImage1.png";
import ProfileImage2 from "./../assets/profileImage2.png";
import UserFriendlyImage from "./../assets/userFriendlyImage.svg";

export default function Landing() {
  const services = [
    {
      icon: SamrtPhoneIcon,
      title: "Features that Connect",
      des: "Dhanak is not just a marketplace; it's a community. Engage in meaningful communication with like-minded individuals, foster connections, and explore new opportunities. Our app facilitates seamless interaction, ensuring that you can build networks, seek collaborations, and support one another in your journey towards success.",
    },
    {
      icon: PlanningIcon,
      title: "Proudly Sell Your Soft Skills",
      des: "We understand that your skills extend beyond traditional offerings. Dhanak allows you to sell your soft skills, be it services, expertise, or unique talents that set you apart. Whether you're a gifted communicator, a creative problem-solver, or have other valuable soft skills, our platform is your stage to monetize what makes you exceptional.",
    },
    {
      icon: PadlockIcon,
      title: "Join the Dhanak Movement",
      des: "Be part of a community that celebrates diversity, talent, and resilience. Download Dhanak now and embark on a journey where your skills are not just seen but appreciated. Together, let's break barriers, defy stereotypes, and create a world where everyone has the opportunity to be proud of their abilities.",
    },
  ];
  const ourTeam = [
    {
      image: ProfileImage2,
      name: "Ch Ibrahim Tahir Farooq Bajwa",
      title: "founder & president Dhanak",
      des: " “Through intuitive design, we're providing a gateway for underprivileged artisans to not just create websites, but to forge connections and livelihoods. The app caters to the unique needs of underrepresented artisans, offering an intuitive interface.'",
    },
    {
      image: ProfileImage1,
      name: "Mikail Faisal Ejaz",
      title: "Co-founder Dhanak",
      des: '"My target is to create a bridge or a connection,  using the principles of UI/UX design.. a business that would, in turn, empower overlooked entrepreneurs, largely gender-based minorities, through designing persuasive websites that generated sales."',
    },
  ];
  return (
    <>
      <Header header={true} />
      <Container>
        <div className="ourGoals">
          <div
            className="sectionMain"
            // style={{ marginBottom: 60, marginTop: 100 }}
          >
            <h1 className="serviceHeading">Categories that Celebrate You</h1>
            <p className="serviceSecDes">
              Dhanak offers a plethora of categories tailored to your skills and
              passions. Whether you're an entrepreneur with a small business, a
              talented artist specializing in hina, or an expert in teaching,
              our app has the perfect space for you. Showcase your products,
              services, and expertise in an environment that appreciates and
              values your unique contributions.
            </p>
          </div>
          <Grid container spacing={2} justifyContent="space-between">
            {services.map((val, i) => {
              return (
                <Grid item xl={3.7} lg={3.7} md={3.7} sm={6} xs={12} key={i}>
                  <div className="serviceCard">
                    <img src={val.icon} />
                    <p className="serviceTitle">{val.title}</p>
                    <p className="serviceDes">{val.des}</p>
                  </div>
                </Grid>
              );
            })}
          </Grid>
        </div>
        <div
          className="sectionMain"
          style={{ marginBottom: 60, marginTop: 100 }}
        >
          <h1 className="serviceHeading">Why Us</h1>
          <p className="serviceSecDes">
            Dhanak is not just an app; it's a movement towards empowerment and
            inclusivity. We have created a revolutionary space where individuals
            from marginalized communities can break barriers and display their
            skills for the world to see. Our platform goes beyond conventional
            norms, embracing diversity in all its vibrant forms.
          </p>
        </div>
      </Container>
      <AboutApp />
      <Container>
        <div id="ourteam">
          <div className="sectionMain">
            <h1 className="serviceHeading">OUR TEAM</h1>
            <p className="serviceSecDes">
              It Is A Long Established Fact That A Reader Will Be Distracted By
              The Readable Content Of A Page When Looking At Its Layout. The
              Point Of Using Lorem
            </p>
          </div>
        </div>
        <Grid container spacing={5} justifyContent="space-between">
          {ourTeam.map((val, i) => {
            return (
              <Grid item xl={5} lg={5} md={5} sm={6} xs={12} key={i}>
                <div className="ourTeamCard">
                  <div className="ourTeamCardHeader">
                    <img src={val.image} />
                    <div>
                      <p className="teamMemberName">{val.name}</p>
                      <p className="teamMemberTitle">{val.title}</p>
                    </div>
                  </div>
                  <p
                    className="serviceDes"
                    style={{ textAlign: "left", marginTop: 20 }}
                  >
                    {val.des}
                  </p>
                </div>
              </Grid>
            );
          })}
        </Grid>
        <div id="">
          <div className="sectionMain">
            <h1 className="serviceHeading">User-Friendly Interface</h1>
            <p className="serviceSecDes">
              It Is A Long Established Fact That A Reader Will Be Distracted By
              The Readable Content Of A Page When Looking At Its Layout.
            </p>
          </div>
          <img src={UserFriendlyImage} className="userFriendlyImage" />
        </div>
      </Container>
      <Contact />
      <Footer />
    </>
  );
}
