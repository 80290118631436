import React, { useState } from "react";
import { Container, Grid } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
// Sections
import Input from "../../components/Input";
import { login } from "../../utils/firebaseQueries";

export default function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("dhanakappadmin@gmail.com");
  const [password, setPassword] = useState("dhanakapp@123");
  const [loading, setLoading] = useState(false);

  const loginHandle = async () => {
    try {
      if (email === "" || password === "") {
        alert("Email and password are required");
        return;
      }
      setLoading(true);
      await login(email, password);
      setEmail("");
      setPassword("");
      navigate("/admin/dashboard");
    } catch (error) {
      if (error.code === "auth/invalid-credential") {
        alert("Password is incorrect");
      } else {
        alert(error.code);
      }
    } finally {
      setLoading(false);
    }
  };

  // dhanakappadmin@gmail.com
  // dhanakapp@123
  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Container>
        <Grid container justifyContent="center">
          <Grid item xl={5} lg={5} md={6} sm={6} xs={12}>
            {/* <div style={{ width: "40%" }}> */}
            <h2>Login</h2>
            <Input
              placeholder="Enter Email"
              value={email}
              onChange={(value) => setEmail(value)}
              label="Email"
              type="email"
            />
            <Input
              placeholder="Enter Password"
              value={password}
              onChange={(value) => setPassword(value)}
              label="Password"
              type="password"
            />
            <div style={{ marginTop: 20 }}>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#1a191e",
                  height: 50,
                  color: "#fff",
                }}
                // fullWidth
                disabled={loading}
                onClick={loginHandle}
              >
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  "Login"
                )}
              </Button>
            </div>
            {/* </div> */}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
