import React from "react";
import "./../style/aboutApp.css";
import { Container, Grid } from "@mui/material";
// Sections
import Header from "../components/Sections/Header";
import Footer from "../components/Sections/Footer";
import AboutApp from "../components/Sections/AboutApp";
import Contact from "../components/Sections/Contact";
// Assets
import SamrtPhoneIcon from "./../assets/smartphone.svg";
import PlanningIcon from "./../assets/planning.svg";
import PadlockIcon from "./../assets/padlock.svg";
import ProfileImage1 from "./../assets/profileImage1.png";
import ProfileImage2 from "./../assets/profileImage2.png";
import UserFriendlyImage from "./../assets/userFriendlyImage.svg";
import AboutUsImage from "./../assets/headerImage.svg";

export default function FAQ() {
  return (
    <>
      <Header header={false} />
      <div className="termsConditionHeader">
        <h1>Frequently asked questions</h1>
      </div>
      <Container style={{ marginTop: 50, marginBottom: 50 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <div>
              <img src={AboutUsImage} className="aboutUseImage" />
            </div>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <div className="aboutUsSec">
              <h2>
                Empowering Marginalized Communities through Skill Showcase
              </h2>
              <p>
                Welcome to Dhanak, your inclusive platform dedicated to
                uplifting the marginalized communities of Pakistan. Whether you
                identify as transgender, belong to a minority group, or are an
                underprivileged entrepreneur or skilled individual, Dhanak is
                your space to shine. We believe in the power of diversity and
                aim to provide a platform where everyone can showcase their
                unique talents and skills proudly.
              </p>
            </div>
          </Grid>
        </Grid>
      </Container>
      <Contact />
      <Footer />
    </>
  );
}
