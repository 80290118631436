import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IoClose } from "react-icons/io5";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import {
  Container,
  Grid,
  Snackbar,
  Alert,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";
// Sections
import ResponsiveDrawer from "../../components/Drawer";
import DataTable from "../../components/Table";
import {
  getAllDocuments,
  setDocument,
  signUp,
  updateDocument,
} from "../../utils/firebaseQueries";
import Input from "../../components/Input";
// Assets
import "./../../style/modal.css";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const userTypes = [
  { label: "Buyer", value: "Buyer" },
  { label: "Seller", value: "Seller" },
];

export default function Users() {
  const [users, setUsers] = useState([]);
  const [username, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [age, setAge] = useState("");
  const [address, setAdress] = useState("");
  const [education, setEducation] = useState("");
  const [interest, setInterest] = useState("");
  const [userType, setUserType] = useState("Select User Type");
  const [userId, setUserId] = useState("");
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const [page, setPage] = useState(0);
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const getUsers = async () => {
    try {
      const res = await getAllDocuments("Users");
      setUsers([...res]);
    } catch (error) {
      setMessage(error.message);
      setMessageType("error");
      setOpen1(true);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  const createUser = async () => {
    try {
      if (
        username === "" ||
        email === "" ||
        password === "" ||
        phoneNumber === "" ||
        age === "" ||
        address === "" ||
        education === "" ||
        interest === ""
      ) {
        setMessage("Kindly fill all the fields");
        setMessageType("error");
        setOpen1(true);
      } else if (userType === "Select User Type") {
        setMessage("Kindly select the user type");
        setMessageType("error");
        setOpen1(true);
      } else {
        setLoading(true);
        const user = await signUp(email, password);
        const data = {
          email,
          username,
          phoneNumberVerified: false,
          providerId: user.providerData[0].providerId,
          photoUrl:
            "https://firebasestorage.googleapis.com/v0/b/dhanak-63de5.appspot.com/o/dummy-profile.png?alt=media&token=76213b12-bc09-46d9-8bbf-744301532a8b",
          coverUrl:
            "https://firebasestorage.googleapis.com/v0/b/dhanak-63de5.appspot.com/o/dummy-cover.png?alt=media&token=9b3cd8bb-7792-4616-bac7-f99bd7361eb0&_gl=1*15zxfs8*_ga*NTY5NjcyMzQxLjE2NjcyOTg2NDI.*_ga_CW55HF8NVT*MTY5OTQ2NjIzNS4yMzEuMS4xNjk5NDY2Mzg4LjM5LjAuMA..",
          phoneNumber,
          phoneNumberVerified: true,
          otherDetails: true,
          age,
          education,
          address,
          interest,
          userType,
        };
        await setDocument("Users", user.uid, data);
        setOpen(false);
        setEmail("");
        setEducation("");
        setPassword("");
        setInterest("");
        setAdress("");
        setAge("");
        setPhoneNumber("");
        setUserName("");
        setMessage("User created successfully");
        setMessageType("success");
        setOpen1(true);
        getUsers();
      }
    } catch (error) {
      setMessage(error.message);
      setMessageType("error");
      setOpen1(true);
    } finally {
      setLoading(false);
    }
  };

  const editUser = async () => {
    try {
      if (
        username === "" ||
        phoneNumber === "" ||
        age === "" ||
        address === "" ||
        education === "" ||
        interest === ""
      ) {
        setMessage("Kindly fill all the fields");
        setMessageType("error");
        setOpen1(true);
      } else if (userType === "Select User Type") {
        setMessage("Kindly select the user type");
        setMessageType("error");
        setOpen1(true);
      } else {
        setLoading(true);
        const data = {
          username,
          phoneNumber,
          age,
          education,
          address,
          interest,
          userType,
        };
        await updateDocument("Users", userId, data);
        setOpen1(true);
        setEmail("");
        setEducation("");
        setPassword("");
        setInterest("");
        setAdress("");
        setAge("");
        setPhoneNumber("");
        setUserName("");
        setMessage("User updated successfully");
        setMessageType("success");
        setOpen2(false);
        getUsers();
      }
    } catch (error) {
      setMessage(error.message);
      setMessageType("error");
      setOpen1(true);
    } finally {
      setLoading(false);
    }
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen1(false);
  };

  return (
    <ResponsiveDrawer title="Users">
      <Container style={{ marginTop: 50, marginBottom: 50 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xl={8} lg={8} md={8} sm={8} xs={12}></Grid>
          <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#1a191e",
                  height: 40,
                  color: "#fff",
                  width: 100,
                  // fontSize:14,
                  // textTransform:"capitalize"
                }}
                disabled={loading}
                onClick={() => setOpen(true)}
              >
                Add
              </Button>
            </div>
          </Grid>
        </Grid>
        {users.length !== 0 && (
          <DataTable
            data={users}
            onHandleEditBtn={(row) => {
              setAge(row.age);
              setUserName(row.username);
              setOpen2(true);
              setPhoneNumber(row.phoneNumber);
              setInterest(row.interest);
              setAdress(row.address);
              setUserType(row.userType);
              setEducation(row.education);
              // setEmail(row.email);
              setUserId(row.id);
            }}
          />
        )}
      </Container>
      {/* CHANGE STATUS MODAL */}
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="xs"
        fullWidth={true}
      >
        <div className="_user_modal_header">
          <div>Create User</div>
          <span onClick={() => setOpen(false)}>
            <IoClose color="#717171" size={25} />
          </span>
        </div>
        <DialogContent dividers>
          <div className="_user_modal_body">
            {/* <div className="modalHeading">Change membership</div> */}
            <Input
              placeholder="Enter Username"
              value={username}
              onChange={(value) => setUserName(value)}
              // label="Email"
              type="text"
            />
            <Input
              placeholder="Enter Email"
              value={email}
              onChange={(value) => setEmail(value)}
              type="email"
            />
            <Input
              placeholder="Enter Password"
              value={password}
              onChange={(value) => setPassword(value)}
              type="password"
            />
            <Input
              placeholder="Enter Phone Number"
              value={phoneNumber}
              onChange={(value) => setPhoneNumber(value)}
              type="number"
            />
            <Input
              placeholder="Enter Age"
              value={age}
              onChange={(value) => setAge(value)}
              type="number"
            />
            <Input
              placeholder="Enter Education"
              value={education}
              onChange={(value) => setEducation(value)}
              type="text"
            />
            <Input
              placeholder="Enter Interest"
              value={interest}
              onChange={(value) => setInterest(value)}
              type="text"
            />
            <Input
              placeholder="Select User Type"
              value={userType}
              onChange={(value) => setUserType(value)}
              type="text"
              select={true}
              data={userTypes}
            />
            <Input
              placeholder="Enter Address"
              value={address}
              onChange={(value) => setAdress(value)}
              type="text"
              multiline
              customStyles={{
                input: {
                  padding: "12px 12px",
                  height: 100,
                },
              }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <div className="_user_modal_footer">
            <Button
              variant="contained"
              style={{
                backgroundColor: "#fff",
                height: 40,
                color: "#fff",
                width: 100,
                marginRight: 15,
                boxShadow: "none",
                border: "1px solid #717171",
                color: "#1a191e",
              }}
              fullWidth
              disabled={loading}
              onClick={() => setOpen(false)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              style={{
                backgroundColor: "#1a191e",
                height: 40,
                color: "#fff",
                width: 100,
                boxShadow: "none",
              }}
              fullWidth
              disabled={loading}
              onClick={() => createUser()}
            >
              {loading ? (
                <CircularProgress color="inherit" size={20} />
              ) : (
                "Create"
              )}
            </Button>
          </div>
        </DialogActions>
      </BootstrapDialog>

      {/* EDIT USER MODAL */}
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={open2}
        maxWidth="xs"
        fullWidth={true}
      >
        <div className="_user_modal_header">
          <div>Create User</div>
          <span
            onClick={() => {
              setOpen2(false);
            }}
          >
            <IoClose color="#717171" size={25} />
          </span>
        </div>
        <DialogContent dividers>
          <div className="_user_modal_body">
            {/* <div className="modalHeading">Change membership</div> */}
            <Input
              placeholder="Enter Username"
              value={username}
              onChange={(value) => setUserName(value)}
              // label="Email"
              type="text"
            />
            {/* <Input
              placeholder="Enter Email"
              value={email}
              onChange={(value) => setEmail(value)}
              type="email"
            />
            <Input
              placeholder="Enter Password"
              value={password}
              onChange={(value) => setPassword(value)}
              type="password"
            /> */}
            <Input
              placeholder="Enter Phone Number"
              value={phoneNumber}
              onChange={(value) => setPhoneNumber(value)}
              type="text"
            />
            <Input
              placeholder="Enter Age"
              value={age}
              onChange={(value) => setAge(value)}
              type="number"
            />
            <Input
              placeholder="Enter Education"
              value={education}
              onChange={(value) => setEducation(value)}
              type="text"
            />
            <Input
              placeholder="Enter Interest"
              value={interest}
              onChange={(value) => setInterest(value)}
              type="text"
            />
            <Input
              placeholder="Select User Type"
              value={userType}
              onChange={(value) => setUserType(value)}
              type="text"
              select={true}
              data={userTypes}
            />
            <Input
              placeholder="Enter Address"
              value={address}
              onChange={(value) => setAdress(value)}
              type="text"
              multiline
              customStyles={{
                input: {
                  padding: "12px 12px",
                  height: 100,
                },
              }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <div className="_user_modal_footer">
            <Button
              variant="contained"
              style={{
                backgroundColor: "#fff",
                height: 40,
                color: "#fff",
                width: 100,
                marginRight: 15,
                boxShadow: "none",
                border: "1px solid #717171",
                color: "#1a191e",
              }}
              fullWidth
              disabled={loading}
              onClick={() => {
                setOpen2(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              style={{
                backgroundColor: "#1a191e",
                height: 40,
                color: "#fff",
                width: 100,
                boxShadow: "none",
              }}
              fullWidth
              disabled={loading}
              onClick={() => editUser()}
            >
              {loading ? (
                <CircularProgress color="inherit" size={20} />
              ) : (
                "Update"
              )}
            </Button>
          </div>
        </DialogActions>
      </BootstrapDialog>
      <Snackbar
        open={open1}
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          severity={messageType}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </ResponsiveDrawer>
  );
}
