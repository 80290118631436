import React, { useState } from "react";
import { Container, Grid } from "@mui/material";
// Assets
// CSS
import "./../../style/contact.css";

export default function Contact() {
  const [check, setCheck] = useState(false);
  const [message, setMessage] = useState("");
  return (
    <div className="contactMain" id="contact">
      <Container>
        <Grid container justifyContent="center" spacing={3}>
          <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
            <div>
              <p className="contactHeading" style={{ textAlign: "left" }}>
                Contact Us
              </p>
              <p className="contactDes">
                There are many variations of passages of Lorem Ipsum but the
                majority have suffered alteration.
              </p>
            </div>
          </Grid>
          <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
            <div>
              <div className="sendMessageSec">
                <input
                  placeholder="Email here"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
                <button className="pointer">Send</button>
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
