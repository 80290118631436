import React from "react";
import { Container, Grid } from "@mui/material";
import "./../../style/aboutApp.css";
// Assets
import AppImage from "./../../assets/iPhoneLight.svg";
import QRCodeImage from "./../../assets/img/downloadQrCode.png";
import ArrowIcon from "./../../assets/arrowIcon2.svg";

export default function AboutApp() {
  return (
    <div className="aboutAppMin" id="aboutApp">
      <Container>
        <div className="appIntroductionMain">
          <div className="appImageMain">
            <img src={QRCodeImage} className="appImage" />
          </div>
          <div className="appIntroMain">
            <h1>Download Dhanak Today</h1>
            <p>
              Embark on a journey of empowerment, connection, and success.
              Download Dhanak now and become part of a community that believes
              in the potential of every individual. Let your skills shine,
              connect with a diverse audience, and join us in creating a future
              where everyone, regardless of background, can be proud of their
              accomplishments.
              {/* <br />
              <br />
              <br />
              It Is A Long Established Fact That A Reader Will Be Distracted By
              The Readable Content Of A Page When Looking At Its Layout. The
              Point Of */}
            </p>
            <div className="appFeauterMain">
              <a
                href="https://expo.dev/accounts/beerbal/projects/Dhanak/builds/0a72a5fb-fbc8-46d8-8904-aab06eb88fd3"
                target="_blank"
              >
                <button>
                  Get More
                  <img src={ArrowIcon} />
                </button>
              </a>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
