import React from "react";
import "./../style/Landing.css";
import { Container, Grid } from "@mui/material";
// Sections
import Header from "../components/Sections/Header";
import Footer from "../components/Sections/Footer";
// Assets
import "./../style/termsConditions.css";

export default function HowToDeleteUserData() {
  return (
    <>
      <Header header={false} />

      <div style={{ marginBottom: 50 }}>
        <div className="termsConditionHeader">
          <h1>Delete User Data</h1>
        </div>
        <Container>
          <p className="termsConditionsHeading" style={{ marginTop: 20 }}>
            Account Deletion:
          </p>
          <p className="termsWelcomeDes">
            If you wish to delete your account and associated data, please
            contact our customer service at{" "}
            <a href="mailto:dhanakapp@gmail.com">dhanakapp@gmail.com</a>. Our
            support team will guide you through the process and address any
            queries you may have regarding the deletion of your data. We are
            here to ensure a smooth experience for you. Thank you for choosing
            Dhanak.
          </p>
        </Container>
      </div>
      <Footer />
    </>
  );
}
