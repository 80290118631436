import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "react-router-dom";
// Assets
import CloseIcon from "../../assets/svg/closeIcon.svg";

export default function Sidebar({ sidebarOpen, toggleSidebar }) {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [moreList, setMoreList] = useState(false);

  useEffect(() => {
    var isUserLogin = localStorage.getItem("userData");
    if (isUserLogin) {
      setIsUserLoggedIn(true);
    }
  }, []);

  return (
    <Wrapper className="animate" sidebarOpen={sidebarOpen}>
      <SidebarHeader className="flexSpaceCenter">
        <CloseBtn
          onClick={() => toggleSidebar(!sidebarOpen)}
          className="animate pointer"
        >
          <img src={CloseIcon} />
        </CloseBtn>
        <h1 className="font20" style={{ textAlign: "center", flex: 1 }}>
          Menu
        </h1>
      </SidebarHeader>

      <UlStyle>
        <MenuItem
          onClick={() => {
            toggleSidebar(!sidebarOpen);
          }}
        >
          <div className="menuList">
            <p>
              <Link to="/">Home</Link>
            </p>
          </div>
        </MenuItem>
        <MenuItem
          onClick={() => {
            toggleSidebar(!sidebarOpen);
          }}
        >
          <div className="menuList">
            <p>
              <Link to="/about-us">About Us</Link>
            </p>
          </div>
        </MenuItem>
        <MenuItem
          onClick={() => {
            toggleSidebar(!sidebarOpen);
          }}
        >
          <div className="menuList">
            <p>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </p>
          </div>
        </MenuItem>
        <MenuItem
          onClick={() => {
            toggleSidebar(!sidebarOpen);
          }}
        >
          <div className="menuList">
            <p>
              <Link to="/community">Community</Link>
            </p>
          </div>
        </MenuItem>
        <MenuItem
          onClick={() => {
            toggleSidebar(!sidebarOpen);
          }}
        >
          <div className="menuList">
            <p>
              <a href="#ourGoals">Our Goals</a>
            </p>
          </div>
        </MenuItem>
        <MenuItem
          onClick={() => {
            toggleSidebar(!sidebarOpen);
          }}
        >
          <div className="menuList">
            <p>
              <a href="#ourteam">Our Team</a>
            </p>
          </div>
        </MenuItem>
        <MenuItem
          onClick={() => {
            toggleSidebar(!sidebarOpen);
          }}
        >
          <div className="menuList">
            <p>
              <a href="#contact">Contact Us</a>
            </p>
          </div>
        </MenuItem>
      </UlStyle>
    </Wrapper>
  );
}

const Wrapper = styled.nav`
  width: 400px;
  height: 100vh;
  position: fixed;
  top: 0;
  padding: 0 30px;
  left: ${(props) => (props.sidebarOpen ? "0px" : "-400px")};
  z-index: 9999;
  background-color: #fff;
  @media (max-width: 400px) {
    width: 100%;
  }
  overflow-y: scroll;
`;
const SidebarHeader = styled.div`
  padding: 20px 0;
`;
const CloseBtn = styled.button`
  border: 0px;
  outline: none;
  background-color: transparent;
  padding: 10px;
`;
const UlStyle = styled.ul`
  // padding: 40px;
  li {
    margin: 20px 0;
  }
`;
const UlWrapperRight = styled.ul`
  @media (max-width: 760px) {
    display: none;
  }
`;
