import React from "react";
import "./index.css";

export default function Input({
  placeholder,
  value,
  onChange,
  label,
  type,
  customStyles,
  multiline,
  select,
  data,
}) {
  console.log(customStyles);
  return (
    <div
      style={{
        marginTop: 20,
        ...customStyles?.main,
        // border: "1px solid black",
      }}
    >
      {label && (
        <div style={{ marginBottom: 10, color: "#707070" }}>{label}</div>
      )}

      {select && (
        <select
          value={value}
          onChange={(e) => onChange(e.target.value)}
          // placeholder={placeholder}
          className="input"
        >
          <option value={placeholder}>{placeholder}</option>
          {data.map((val, i) => (
            <option key={i} value={val.value}>
              {val.label}
            </option>
          ))}
        </select>
      )}
      {multiline && (
        <textarea
          value={value}
          onChange={(e) => onChange(e.target.value)}
          placeholder={placeholder}
          style={{
            ...customStyles?.input,
            backgroundColor: "#f3f6f9",
            border: "1px solid #717171",
            width: "100%",
            borderRadius: 5,
            outline: "none",
            fontSize: 16,
            color: "#1a191e",
            fontFamily: "Outfit",
          }}
        ></textarea>
      )}
      {!multiline && !select && (
        <input
          type={type}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          placeholder={placeholder}
          className="input1"
        />
      )}
    </div>
  );
}
