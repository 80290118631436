import React, { useEffect, useState } from "react";
// Assets
import LogoImg from "../../assets/svg/Logo";
import { Container, Grid } from "@mui/material";
import Logo from "./../../assets/logo.svg";
import AppleStore from "./../../assets/img/AppleStore.svg";
import PlayStore from "./../../assets/img/GoogleStore.svg";
import {
  BsArrowRightShort,
  BsYoutube,
  BsTwitter,
  BsInstagram,
} from "react-icons/bs";
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./../../style/footer.css";

export default function Footer() {
  const [message, setMessage] = useState("");

  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  return (
    <div className="footerMain">
      <Container>
        <Grid container>
          <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
            <div>
              <img src={Logo} />
              {/* <p className="footerDes">
                Pay World's online banking app offers seamless and secure
                financial management on the go. Easily check balances, transfer
                funds, pay bills, and track transactions. Take full control of
                your finances, right at your fingertips.
              </p> */}
              <div className="socialLinks">
                <a href="#">
                  <BsYoutube color="#9C9CA8" />
                </a>
                <a href="#">
                  <FaFacebookF color="#9C9CA8" />
                </a>
                <a href="#">
                  <BsTwitter color="#9C9CA8" />
                </a>
                <a href="#">
                  <BsInstagram color="#9C9CA8" />
                </a>
                <a href="#">
                  <FaLinkedinIn color="#9C9CA8" />
                </a>
              </div>
            </div>
          </Grid>
          <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
            <div className="footerLinksMain">
              <p>Links</p>
              <a>
                <Link to="/">Home</Link>
              </a>
              <a>
                <Link to="/about-us">About Us</Link>
              </a>
              <a href="#aboutApp">Download</a>
              <a href="#ourteam">Team</a>
              <a href="#contact">Contact us</a>
            </div>
          </Grid>
          <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
            <div className="footerLinksMain">
              <p>Support</p>
              <a>
                <Link to="/faq">FAQ</Link>
              </a>
              <a href="#">How it Works</a>
              <a href="#contact">Contact</a>
              <a href="#">Reporting</a>
            </div>
          </Grid>
          <Grid item xl={2} lg={2} md={2} sm={12} xs={12}>
            <div className="footerLinksMain">
              <p>Support</p>
              <a href="Tel:+92333 3098333">+92333 3098333</a>
              <a href="mailto:humeradhanak@gmail.com">humeradhanak@gmail.com</a>
              {/* <a href="#">Contact</a> */}
              <a>Lahore,Pakistan</a>
            </div>
          </Grid>
        </Grid>
        <div className="bottomSec">
          <div className="socialLinks">
            <p>copyright @2023 Dhanak All Rights Reserved</p>
          </div>
          <div className="bottomSecTxt">
            <a href="#">Terms of use</a> | <a href="#">Privacy Policy</a>
          </div>
        </div>
      </Container>
    </div>
  );
}
