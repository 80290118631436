import React, { useState } from "react";
import "./../style/community.css";
import { Container, Grid } from "@mui/material";
// Sections
import Header from "../components/Sections/Header";
import Footer from "../components/Sections/Footer";
import Contact from "../components/Sections/Contact";
// Assets
import Image1 from "../assets/img/image1.jpg";
import Image2 from "../assets/img/image2.jpg";
import Image3 from "../assets/img/image3.jpg";
import Image4 from "../assets/img/image4.jpg";
import Image5 from "../assets/img/image5.jpg";
import Image6 from "../assets/img/image6.png";
import Image7 from "../assets/img/image7.jpg";
import Image8 from "../assets/img/image8.png";
import Image9 from "../assets/img/image9.jpg";
import Image10 from "../assets/img/image10.jpg";
import Image11 from "../assets/img/image11.jpg";
import Image12 from "../assets/img/image12.jpg";
import Image13 from "../assets/img/image13.jpg";
// import AboutUsImage from "./../assets/headerImage.svg";

// const communities = [];

export default function Community() {
  const [communities, setCommunities] = useState([
    {
      name: "Mahi",
      profession: "Beautician, Dancer",
      image: Image1,
      phoeNumber: "03237977500",
      seeMore: false,
      des: "Mahi recognizes herself as transgender women and is a professional beautician holding International aesthetics certificates. She is an active member of Khawaja Sira Society. Other than makeup she is also performs/dances for wedding functions. You can reach out to Mahi for makeup services or for dance performance via Dhanak app. Our website or simply DM on our instagram handle.",
    },
    {
      name: "Lucky Khan",
      profession: "Singer",
      image: Image3,
      phoeNumber: "03226458803",
      seeMore: false,
      des: "Lucky khan recognizes herself as transgender women and is a professional Singer. Lucky have done many TV commercials as a background singer. She also performed for Coke studio and is greatly admired. If u want to invite Lucky khan for Musical nights please contact on her number given below or via Dhanak app. Our website or simply DM on our instagram handle.",
    },
    {
      name: "Mahi Piyasi",
      profession: "Cook",
      image: Image2,
      phoeNumber: "03226458803",
      seeMore: false,
      des: "Mahi recognizes herself as transgender women and is a professional cook. She can make both Pakistani and Chinese dishes. She can easily cook food for small parties comprising of 25 to 50 people. Other than taking orders Mahi Piyasi also have a Biryani cart near Kot-Luckhpat. You can reach out to Mahi via Dhanak app. Our website or simply DM on our insta handle.",
    },
    {
      name: "Farooq Rajpoot",
      profession: "Makeup Artist",
      image: Image5,
      phoeNumber: "03021486244",
      seeMore: false,
      des: "Farooq Rajpoot recognizes himself as transgender man and is a professional beautician with experience of working with top models and some big fashion events. You can reach out to Farooq Rajpoot for makeup services via Dhanak app. Our website or simply DM on our insta handle.",
    },
    {
      name: "Petra",
      profession: "Kathak dancer, Performer, Social Activist",
      image: Image4,
      phoeNumber: "03246273844",
      seeMore: false,
      des: "Petra recognizes herself as transgender women and is a professional dancer plus thematic performer. She is an active member of Khawaja Sira Society. You can reach out to Petra via Dhanak app. Our website or simply DM on our insta handle.",
    },
    {
      name: "Maala",
      profession: "Committee, Counsellor",
      image: Image7,
      phoeNumber: "03032326635",
      seeMore: false,
      des: "Mala recognizes herself as transgender women and is an active member of Khawaja Sira Society. You can reach out to Mala via Dhanak app. Our website or simple DM on our insta handle.",
    },
    {
      name: "Emaan",
      profession: "Actor, Makeup Artist, Professional Dancer",
      image: Image6,
      phoeNumber: "03256572704",
      seeMore: false,
      des: "Emaan recognizes herself as transgender women and is a profound Actor and beautician. She is an active member of Khawaja Sira Society. Eman performed at OLompolo Media and other theater shows too. Other than makeup she is also performs/dances for wedding functions. You can reach out to Eman for acting, smakeup services or for dance performance via dhanak app. Our website or simply DM on our insta handle.",
    },
    {
      name: "Saima Butt",
      profession:
        "Kathak Dance teacher, Social activist, Worker at Khawaja Sira Society",
      image: Image9,
      phoeNumber: "03004382622",
      seeMore: false,
      des: "Saima recognizes herself as transgender women and an active member of Khawaja Sira Society. Saima trained many persons of Khawaja sira community in Kathak and teaches kathak to people outside the community too. You can reach out to Saima via dhanak app. Our website or simply DM on our insta handle.",
    },
    {
      name: "Nadia",
      profession: "Professional Tailor",
      image: Image8,
      phoeNumber: "03054609745",
      seeMore: false,
      des: "Nadia , a member of Khawaja Sira community is an expert tailor of women’s clothing including, shalwar suit, saree and lehnga etc. Support Nadia’s skill by contacting her through Dhanak app, our website or simple DM on our insta handle.",
    },
    {
      name: "MOMO",
      profession: "Cook",
      image: Image12,
      phoeNumber: "03074008512",
      seeMore: false,
      des: "Momo , a member of Khawaja Sira community is a supplier of frozen samosa and rolls etc. for more details contact Momo on this number  03074008512. Boost Momo’s bussiness by contacting her through Dhanak app, our website or simple DM on our insta handle.",
    },
    {
      name: "Shehla",
      profession: "Dancer, Wedding performer",
      image: Image10,
      phoeNumber: "03034295024",
      seeMore: false,
      des: "Shehla , a member of Khawaja Sira community and a dance performer especially for wedding functions.  You can reach out to Shehla through Dhanak app, Dhanak website or simply DM on Dhanak  insta handle.",
    },
    {
      name: "Nadia Rajpoot",
      profession: "Dancer for wedding functions",
      image: Image11,
      phoeNumber: "03274137816",
      seeMore: false,
      des: "Nadia, a member of Khawaja Sira community and a dance performer especially for wedding functions.  You can reach out to Nadia through Dhanak app, Dhanak website or simply DM on Dhanak  insta handle.",
    },
    {
      name: "Bilalu",
      profession: "Stitching",
      image: Image13,
      phoeNumber: "030242112",
      seeMore: false,
      des: "Bilalu , a member of Khawaja Sira community is an expert tailor of women’s clothing including, shalwar suit, saree and lehnga etc. Support Bilalu’s skill by contacting her through Dhanak app, our website or simple DM on our insta handle.",
    },
  ]);
  return (
    <>
      <Header header={false} />
      <div className="termsConditionHeader">
        <h1>Community</h1>
      </div>
      <Container style={{ marginTop: 50, marginBottom: 50 }}>
        <Grid container spacing={2}>
          {communities.map((val, i) => {
            return (
              <Grid item xl={3} lg={3} md={4} sm={4} xs={12} key={i}>
                <div className="communityMemberCard">
                  <img src={val.image} className="communityMemberImage" />
                  <p className="communityMemberName">{val.name}</p>
                  <p className="communityMemberProfession">{val.profession}</p>
                  <p className="communityMemberNumber">{val.phoeNumber}</p>
                  <p className="communityMemberDes">
                    {val.seeMore ? val.des : val.des.slice(0, 120)}
                  </p>
                  <p
                    className="seeMoreBtn"
                    onClick={() => {
                      communities[i].seeMore = !communities[i].seeMore;
                      setCommunities([...communities]);
                    }}
                  >
                    {val.seeMore ? "see less" : "see more"}
                  </p>
                </div>
              </Grid>
            );
          })}
        </Grid>
      </Container>
      <Contact />
      <Footer />
    </>
  );
}
