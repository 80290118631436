import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Logo from "./../../assets/logo.svg";
import { MdOutlineDashboard } from "react-icons/md";
import { TbLogout2 } from "react-icons/tb";
import { FiUsers } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { logout } from "../../utils/firebaseQueries";

const drawerWidth = 240;

function ResponsiveDrawer(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const { window, children, title } = props;
  const { pathname } = location;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);
  // console.log("=====location====", location);
  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const menuItem = [
    {
      title: "Dashboard",
      path: "/admin/dashboard",
    },
    {
      title: "Users",
      path: "/admin/users",
    },
  ];

  const logoutHandle = async () => {
    try {
      await logout();
      navigate("/admin/login");
    } catch (error) {
      console.log(error);
    }
  };

  const drawer = (
    <div>
      <Toolbar>
        <img src={Logo} height={40} width={60} />
        <h2>Dhanak</h2>
      </Toolbar>
      <Divider />
      <List>
        {menuItem.map((menu, index) => (
          <ListItem
            key={index}
            disablePadding
            style={{
              borderLeft:
                pathname === menu.path ? "4px solid #1a191e" : "0px solid #fff",
              marginLeft: 10,
              width: "90%",
            }}
          >
            <ListItemButton
              onClick={() => navigate(menu.path)}
              style={{ height: 45 }}
            >
              {/* <ListItemIcon> */}
              {menu.path === "/admin/dashboard" && (
                <MdOutlineDashboard
                  color={
                    pathname === "/admin/dashboard" ? "#1a191e" : "#707070"
                  }
                  size={20}
                />
              )}
              {menu.path === "/admin/users" && (
                <FiUsers
                  color={pathname === "/admin/users" ? "#1a191e" : "#707070"}
                  size={20}
                />
              )}
              {/* </ListItemIcon> */}
              <div
                style={{
                  color: pathname === menu.path ? "#1a191e" : "#707070",
                  fontSize: 16,
                  marginLeft: 10,
                }}
              >
                {menu.title}
              </div>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        <ListItem
          disablePadding
          style={{
            marginLeft: 10,
            width: "90%",
          }}
        >
          <ListItemButton onClick={logoutHandle} style={{ height: 45 }}>
            <TbLogout2 color={"#707070"} size={20} />
            <div
              style={{
                color: "#707070",
                fontSize: 16,
                marginLeft: 10,
              }}
            >
              Logout
            </div>
          </ListItemButton>
        </ListItem>
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          backgroundColor: "#F9F9FF",
        }}
      >
        <Toolbar>
          <IconButton
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon color="#000" />
          </IconButton>
          <div style={{ color: "#1a191e", fontSize: 18, fontWeight: 500 }}>
            {title}
          </div>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{
          width: { sm: drawerWidth },
          flexShrink: { sm: 0 },
        }}
        aria-label="mailbox folders"
        style={{ backgroundColor: "#F9F9FF" }}
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
            backgroundColor: "#F9F9FF",
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          // p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * Remove this when copying and pasting into your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;
