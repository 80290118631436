// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { GoogleAuthProvider, getAuth } from 'firebase/auth';
import firebase from 'firebase/compat/app';
import { getStorage } from 'firebase/storage';
import { getFirestore } from 'firebase/firestore';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCgs7IKsIWLH4vF4WyuI2v3HLEvW7d6N6U",
    authDomain: "dhanakapp-b4e7d.firebaseapp.com",
    projectId: "dhanakapp-b4e7d",
    storageBucket: "dhanakapp-b4e7d.appspot.com",
    messagingSenderId: "338092280537",
    appId: "1:338092280537:web:8e4b1e538809f270f24cc8",
    measurementId: "G-FCBM5Z3YKH",
};

export const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const db = getFirestore(app);

export const auth = getAuth(app);